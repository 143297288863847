<template>
  <b-card title="График">
    <div class="d-flex justify-content-between flex-wrap">
      <div class="mb-1 mb-sm-0">
        <span class="text-muted">Показы и заявки</span>
      </div>
    </div>

    <!-- echart -->
    <div>
      <e-charts
        v-blur="isBlurred"
        ref="line"
        autoresize
        :options="option"
        theme="theme-color"
        auto-resize
      />
      <b-spinner
        variant="primary"
        class="stat-chart-spinner"
        v-if="isBlurred"
      />
    </div>
  </b-card>
</template>

<script>
import { BCard, BSpinner } from "bootstrap-vue";
import ECharts from "vue-echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/line";
import theme from "./theme.json";

ECharts.registerTheme("theme-color", theme);

export default {
  props: ["dates", "answersStat", "showStat", "isDisabled"],
  components: {
    BCard,
    ECharts,
    BSpinner,
  },
  computed: {
    option() {
      return {
        legend: {
          data: [
            {
              name: "Показы",
              icon: "rect",
            },
            {
              name: "Заявки",
              icon: "rect",
            },
          ],
        },
        visualMap: [
          {
            show: true,
            type: "continuous",
            min: 0,
            max: 400,
          },
        ],
        grid: {
          width: "96%",
          left: "30px",
          top: "10px",
          show: false,
        },
        tooltip: {
          show: !this.isDisabled,
          trigger: "axis",
        },
        xAxis: {
          boundaryGap: false,
          type: "category",
          data: this.dates,
        },
        yAxis: {
          splitLine: { show: false },
          type: "value",
        },
        series: [
          {
            name: "Показы",
            showSymbol: false,
            data: this.showStat,
            type: "line",
          },
          {
            name: "Заявки",
            showSymbol: false,
            data: this.answersStat,
            type: "line",
          },
        ],
      };
    },
    isBlurred() {
      if (this.dates && this.answersStat && this.showStat) {
        return false;
      }
      return true;
    },
  },
  data() {
    return {};
  },
};
</script>

<style>
.stat-chart-spinner {
  width: 5rem;
  height: 5rem;
  position: absolute;
  top: 40%;
  left: 45%;
}
</style>
