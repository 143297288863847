<template>
  <div>
    <b-row class="mb-1">
      <b-col
        v-for="item in statisticsItems"
        :key="item.icon"
        cols="6"
        md="3"
        class="mb-0"
        :class="item.customClass"
      >
        <b-card no-body class="card-statistics mb-0">
          <b-card-body class="statistics-body">
            <b-media no-body v-if="stat">
              <b-media-aside class="mr-2">
                <b-avatar size="48" :variant="item.color">
                  <feather-icon size="24" :icon="item.icon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ item.title }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.subtitle }}
                </b-card-text>
              </b-media-body>
            </b-media>
            <b-spinner :variant="item.spinner" label="Loading" v-else />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BBadge,
  BSpinner,
} from "bootstrap-vue";

export default {
  props: ["stat"],
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BBadge,
    BSpinner,
  },
  computed: {
    statisticsItems() {
      return [
        {
          icon: "EyeIcon",
          color: "light-primary",
          spinner: "primary",
          title: this.stat ? this.stat.shows : "-",
          subtitle: "Показов",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "ArrowUpRightIcon",
          color: "light-info",
          spinner: "info",
          title: this.stat ? this.stat.interactions : "-",
          subtitle: "Раскрытия",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "InboxIcon",
          color: "light-danger",
          spinner: "danger",
          title: this.stat ? this.stat.answers : "-",
          subtitle: "Заявок",
          customClass: "mb-2 mb-sm-0",
        },
        {
          icon: "DivideIcon",
          color: "light-success",
          spinner: "success",
          title: this.stat ? this.stat.conversion + "%" : "-",
          subtitle: "Конверсия",
          customClass: "",
        },
      ];
    },
  },
  data() {
    return {};
  },
};
</script>
