<template>
  <div>
    <div class="custom-search mb-2">
      <b-row>
        <b-col md="4">
          <label for="lead-type"> Выберите проект: </label>
          <b-form-select
            id="lead-type"
            :options="ProjectsList"
            v-model="filters.projectId"
            @change="projectFilter"
          >
          </b-form-select>
        </b-col>
        <b-col md="4">
          <label for="lead-type"> Выберите даты: </label>
          <flat-pickr
            class="form-control"
            placeholder="..."
            :config="configDateRange"
            v-model="dateRange"
            @on-change="dateRangeChanged"
            @on-close="dateRangeSelected"
          />
        </b-col>
      </b-row>
    </div>
    <statistic-card :stat="overallStat"> </statistic-card>
    <statistic-chart
      v-blur="getIsDisabled"
      :dates="dates"
      :showStat="showStat"
      :answersStat="answersStat"
      :isDisabled="getIsDisabled"
    >
    </statistic-chart>
  </div>
</template>

<script>
import statisticChart from "./components/StatisticChart.vue";
import StatisticCard from "./components/StatisticCard.vue";
import { mapActions, mapGetters } from "vuex";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Russian } from "flatpickr/dist/l10n/ru.js";

import { BCol, BRow, BFormSelect, BSpinner } from "bootstrap-vue";
export default {
  components: {
    BCol,
    BRow,
    BFormSelect,
    BSpinner,
    statisticChart,
    StatisticCard,
    flatPickr,
  },
  data() {
    return {
      filters: {},
      configDateRange: {
        minDate: null,
        maxDate: new Date(),
        locale: Russian,
        dateFormat: "d.m.Y",
        mode: "range",
      },
      dateRange: null,
      initRequest: {
        dateStart: null,
        dateEnd: null,
      },
      blurOptions: {
        isBlurred: this.getIsDisabled,
        opacity: 0.1,
        filter: "blur(2px)",
      },
    };
  },
  metaInfo() {
    return {
      title: "moovee - Заявки",
    };
  },
  computed: {
    ...mapGetters("projects", ["getProjects"]),
    ...mapGetters("statistics", [
      "getShowsList",
      "getAnswersList",
      "getInteractionsList",
    ]),
    ...mapGetters("user", ["getIsDisabled"]),
    overallStat() {
      if (
        this.getAnswersList &&
        this.getShowsList &&
        this.getInteractionsList
      ) {
        const answers = this.getIsDisabled
          ? "****"
          : this.getAnswersList.length;
        const shows = this.getIsDisabled ? "****" : this.getShowsList.length;
        const interactions = this.getIsDisabled
          ? "****"
          : this.getInteractionsList.length;
        let stat = {
          answers,
          shows,
          interactions,
          conversion: 0,
        };
        if (this.getIsDisabled) {
          stat.conversion = "****";
        } else
          stat.conversion =
            stat.shows === 0
              ? 0
              : Math.round((stat.answers / stat.shows) * 100 * 10) / 10;
        return stat;
      } else {
        return null;
      }
    },
    dates() {
      if (
        this.getAnswersList &&
        this.getShowsList &&
        this.getInteractionsList
      ) {
        const showDates = this.getShowsList.map((show) =>
          new Date(show.timeAt.seconds * 1000).toLocaleDateString("ru-RU")
        );
        const answersDates = this.getAnswersList.map((answer) =>
          new Date(answer.timeAt.seconds * 1000).toLocaleDateString("ru-RU")
        );
        const sortedDates = [...answersDates, ...showDates].sort((a, b) =>
          a.localeCompare(b)
        );
        const uniqueDates = [...new Set(sortedDates)];
        return uniqueDates;
      } else return null;
    },
    showStat() {
      if (
        this.getAnswersList &&
        this.getShowsList &&
        this.getInteractionsList
      ) {
        const showsArr = [];
        this.dates.forEach((date) => {
          const filteredShows = this.getShowsList.filter((show) => {
            return (
              new Date(show.timeAt.seconds * 1000).toLocaleDateString(
                "ru-RU"
              ) === date
            );
          });
          showsArr.push(filteredShows.length);
        });
        return showsArr;
      } else return null;
    },
    answersStat() {
      if (
        this.getAnswersList &&
        this.getShowsList &&
        this.getInteractionsList
      ) {
        const answersArr = [];
        this.dates.forEach((date) => {
          const filteredAnswers = this.getAnswersList.filter((answer) => {
            return (
              new Date(answer.timeAt.seconds * 1000).toLocaleDateString(
                "ru-RU"
              ) === date
            );
          });
          answersArr.push(filteredAnswers.length);
        });
        return answersArr;
      } else return null;
    },
    ProjectsList() {
      const list = this.getProjects.map((project) => {
        return {
          value: project.id,
          text: project.name,
        };
      });
      return list;
    },
  },
  methods: {
    ...mapActions("projects", ["requestProjects"]),
    ...mapActions("lead-forms", ["requestAnswersObj"]),
    ...mapActions("statistics", [
      "requestShowsList",
      "requestAnswersList",
      "requestInteractionsList",
      "filterByProject",
      "filterStat",
      "clearStatList",
    ]),
    ...mapActions("user", ["requestUserData"]),
    projectFilter() {
      const filter = {
        projectId: this.filters.projectId,
        dateStart: this.filters.dateStart,
        dateEnd: this.filters.dateEnd,
      };
      this.filters = filter;
      this.clearStatList();
      this.requestShowsList(filter);
      this.requestAnswersList(filter);
      this.requestInteractionsList(filter);
    },
    dateRangeSelected(selectedDates) {
      if (this.isDateRangeChanged) {
        this.isDateRangeChanged = false;
        const filter = {
          projectId: this.filters.projectId,
          dateStart: selectedDates[0],
          dateEnd: selectedDates[1],
        };
        this.filters = filter;
        this.clearStatList();
        this.requestShowsList(filter);
        this.requestAnswersList(filter);
        this.requestInteractionsList(filter);
      }
    },
    dateRangeChanged() {
      this.isDateRangeChanged = true;
    },
  },
  created() {
    this.requestUserData();
    this.requestProjects().then((ans) => {
      if (this.$route.query.project)
        this.filters.projectId = this.$route.query.project;
      else this.filters.projectId = ans[0].id;
      let dateEnd = new Date();
      this.filters.dateEnd = dateEnd;
      let dateStart = new Date(dateEnd - 86400000 * 7);
      this.filters.dateStart = dateStart;
      dateStart = dateStart.toLocaleDateString("ru-RU");
      dateEnd = dateEnd.toLocaleDateString("ru-RU");
      this.dateRange = `${dateStart} — ${dateEnd}`;
      const initFilter = {
        projectId: this.filters.projectId,
        dateStart: this.filters.dateStart,
        dateEnd: this.filters.dateEnd,
      };
      this.requestShowsList(initFilter);
      this.requestAnswersList(initFilter);
      this.requestInteractionsList(initFilter);
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.echarts {
  width: 100% !important;
}
</style>
